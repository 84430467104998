<template>
  <v-row justify="center">
    <v-dialog v-model="shower" scrollable persistent max-width="600px">
      <v-overlay :value="loaddataprogress" z-index="3" color="black" dark>
        <v-progress-circular indeterminate size="100" color="white">
          <span :style="colorProgress">กำลังเรียกคืน Inbox ในปีปัจจุบัน...</span>
        </v-progress-circular>
      </v-overlay>
      <v-card>
        <v-card-title>{{ $t("myinboxPage.consent") }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-list v-if="$t('default') === 'en'">
            <v-list-item v-for="(item, i) in features" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.consent_system_text"></v-list-item-title>
                 <!-- style="color: grey"  -->
                <a  @click="policy(item.policy)">{{ $t("myinboxPage.stipulation") }}</a>
              </v-list-item-content>
              <v-list-item-action class="darkmode">
                <!-- <v-switch
                  inset
                  :color="color.theme"
                  v-model="item.status"
                  @change="statusConsent(item)"
                ></v-switch> -->
                <v-btn-toggle
                  v-model="item.status"
                  rounded
                  dense 
                  :mandatory="item.status !== 'wait'"
                  :color="item.status === 'unapprove' ? 'red' : 'green'"
                >
                  <v-btn value="unapprove" @click="statusConsent(item)">{{ $t("myinboxPage.reject") }}</v-btn>
                  <v-btn value="approve" @click="statusConsent(item)">{{ $t("myinboxPage.accept") }}</v-btn>
                </v-btn-toggle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
          <v-list v-else>
            <v-list-item v-for="(item, i) in features" :key="i">
              <v-list-item-content>
                <v-list-item-title v-text="item.consent_system_text"></v-list-item-title>
                <!-- style="color: grey"  -->
                <a @click="policy(item.policy)">{{ $t("myinboxPage.stipulation") }}</a>
              </v-list-item-content>
              <v-list-item-action class="darkmode">
                <!-- <v-switch
                  inset
                  :color="color.theme"
                  v-model="item.status"
                  @change="statusConsent(item)"
                ></v-switch> -->
                <v-btn-toggle
                  v-model="item.status"
                  rounded
                  dense 
                  :mandatory="item.status !== 'wait'"
                  :color="item.status === 'unapprove' ? 'red' : 'green'"                  
                >
                  <v-btn value="unapprove" @click="statusConsent(item)">{{ $t("myinboxPage.reject") }}</v-btn>
                  <v-btn value="approve" @click="statusConsent(item)">{{ $t("myinboxPage.accept") }}</v-btn>
                </v-btn-toggle>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" outlined @click="$emit('close')">{{ $t("admin.close") }}</v-btn>
          <v-btn
            :color="color.theme"
            class="white--text"
            @click="settingfeature()"
            :disabled="isConsentWait() || isConsentEmpty()"
          >
            {{ $t("myinboxPage.settingHeader.submit") }}
          </v-btn>
        </v-card-actions>

        <dialogpolicy
          :show="opendialogpolicy"
          :datapolicy="selectedpolicy"
          @close="opendialogpolicy = false"          
        ></dialogpolicy>        
        <dialogconsentdetail
          :show="opendialogconsentdetail"
          :statusconsent="status_consent"
          @close="opendialogconsentdetail = false"
        ></dialogconsentdetail>

      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
const dialogpolicy = () => import("../optional/dialog-policy.vue");
const dialogconsentdetail = () => import("../optional/dialog-consentdetail.vue");

const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000
});

export default {
  props: ['show', "dataconsent"],
  components: {
    dialogpolicy,
    dialogconsentdetail,
  },
  data() {
    return {
      nubcheck:0,
      loaddataprogress:false,
      opendialogconsentdetail: false,
      status_consent: "",
      features: [],
      opendialogpolicy: false,
      selectedpolicy: {},
    }
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "color"]),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
      "dataCheckPackage",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },  
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },  
    shower: {
      get() {
        if (this.show === true) {
          this.features = this.dataconsent.map((item) => {
            return {
              ...item,
              consent_system_text: this.$t("myinboxPage.openConsent") + " " + item.consent_system + " " + item.system_business_name,
              // status: item.status === 'approve'
            }
          })
        }
        return this.show
      },
    },
  },
  methods: {
    policy(item) {
      this.opendialogpolicy = true;
      this.selectedpolicy = item;
    },
    statusConsent(item) {
      this.opendialogconsentdetail = true;
      this.status_consent = item;
    },
    isConsentWait() {
      return this.features.filter(f => f.status === 'wait').length > 0
    },
    isConsentEmpty() {
      return this.features.length === 0
    },
    async settingfeature() {
      this.loaddataprogress = true;
      this.nubcheck = 0;
      for (let feature of this.features) { 
        var payload = {
          account_id: this.dataAccountId,
          business_id: this.dataAccountActive.business_info.business_id,
          system_consent: feature.system_id,
          // consent_status: feature.status ? "approve" : "unapprove",
          consent_status: feature.status,
          one_biz_id: this.dataAccountActive.business_info.id,
        }
        console.log('payload', payload);
        let auth = await gbfGenerate.generateToken();
        this.axios
          .post(process.env.VUE_APP_SERVICE_AUTHORIZE_API + "/api/set_accept_system", payload, {
            headers: { Authorization: auth.code },
          })
          .then(response => {
            if (response.data.status === "OK") {
        
              this.nubcheck += 1;
              // console.log("this.nubcheck",this.nubcheck);
              // console.log("this.features.length",this.features.length);
              if(this.features.length == this.nubcheck){  
                Toast.fire({
                icon: "success",
                title: this.$t("toast.consentSuccess")
                  });
                console.log(this.features.length == this.nubcheck);
                this.$emit('submit');
                this.$emit('close');
                this.loaddataprogress = false;
              }
              

            } else {
              Toast.fire({
                icon: "error",
                title: response.data.errorCode + ': ' + response.data.errorMessage,
              });
              this.loaddataprogress = false;
              this.$emit('close');
            }
          }).catch(error => {
            console.log("error", error);  
            this.loaddataprogress = false;
            Toast.fire({
              icon: "error",
              title: error
            });
          })
      }
   
    },
  },
}
</script>
